import React from "react"
import { graphql } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../components/layout/layout"
import { Section } from "../../components/sections/section"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout backgroundStyle="white">
      <Seo
        title={frontmatter.title}
        description={frontmatter.description}
        thumbnail_name={frontmatter.thumbnail_name}
      />
      <Section>
        <div className="container is-max-desktop">
          <div className="content">
            <h1 className="title is-size-3 mt-6">{frontmatter.title}</h1>
            <div className="subtitle is-size-6">{frontmatter.date}</div>
            <div className="mb-6" dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        description
        thumbnail_name
      }
    }
  }
`
